import "./AdsStyleContact.css";

const Ads_04 = () => {
  return (
    <div className="Ads-contact">
      <div className="Ads_cards-contact">

        <div className="Ads_card-content-Contact">
          <div className="Ads_card-box">
            <div className="Ads_card-icon">
              <img src="/imagesADS/ads_03.png" alt="Ads" />
            </div>

            <p>ADS</p>
          </div>
        </div>

        <div className="Ads_card-content-Contact">
          <div className="Ads_card-box">
            <div className="Ads_card-icon">
              <img src="/imagesADS/ads_03.png" alt="Ads" />
            </div>

            <p>ADS</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Ads_04;
