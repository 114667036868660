import "./AdsStyle.css";

const Ads_02 = () => {
  return (
    <div className="Ads">
      <div className="Ads_cards">
        <div className="Ads_card-content">
          <div className="Ads_card-box">
            <div className="Ads_card-icon">
              <img src="/imagesADS/ads_03.png" alt="Ads" />
            </div>

            <p>ADS</p>
          </div>
        </div>

        <div className="Ads_card-content">
          <div className="Ads_card-box">
            <div className="Ads_card-icon">
              <img src="/imagesADS/ads_03.png" alt="Ads" />
            </div>

            <p>ADS</p>
          </div>
        </div>

        <div className="Ads_card-content">
          <div className="Ads_card-box">
            <div className="Ads_card-icon">
              <img src="/imagesADS/ads_03.png" alt="Ads" />
            </div>

            <p>ADS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads_02;
