import "./Whatsapp.css";

const Whatsapp = () => {
  return (
    <div className="Whatsapp">
      <div>
        <a
          href="https://wa.me/351932015787?text=Alguma Duvida? Converse Connosco !!"
          target="_blank"
        >
          <div className="Whatsapp_box"></div>
        </a>
      </div>
    </div>
  );
};

export default Whatsapp;
